import { graphql, useStaticQuery } from "gatsby"
import React from "react"

const MdxRequest = () => {
  const { allMdx } = useStaticQuery(
    graphql`
      query Mdx {
        allMdx {
          nodes {
            frontmatter {
              title
              date
              tag
              path
              img
              description
              alt
            }
          }
        }
      }
    `
  )

  return allMdx.nodes
}

export default MdxRequest
