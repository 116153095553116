import React from "react"
import Layout from "../components/layoutmdx"
import Seo from "../components/seo"
import ShowPosts from "../components/showPosts"

const Blog = () => {
  return (
    <Layout>
      <Seo title="Blog" />
      <ShowPosts />
    </Layout>
  )
}

export default Blog
