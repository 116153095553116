import React from "react"
import MdxRequest from "./helpers/mdxRequest"
import Post from "./Post"
import styled from "styled-components"

const BlogShow = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: auto;
`

const ShowPosts = () => {
  const posts = MdxRequest()

  return (
    <BlogShow>
      {posts.map(post => (
        <Post metadata={post.frontmatter} />
      ))}
    </BlogShow>
  )
}

export default ShowPosts
