import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { GatsbyImage } from "gatsby-plugin-image"

const UseImage = (imgName, alt) => {
  const { allImageSharp } = useStaticQuery(graphql`
    query {
      allImageSharp {
        edges {
          node {
            id
            fluid {
              originalName
            }
            gatsbyImageData
          }
        }
      }
    }
  `)

  const findImage = allImageSharp.edges.find(
    node => node.node.fluid.originalName == imgName
  )

  const gimg = getImage(findImage.node)

  return <GatsbyImage image={gimg} alt={alt} objectFit="contain" />
}

export default UseImage
