import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import UseImage from "./helpers/useImage"

const CardBody = styled.div`
  font-family: "Rubik";
  color: var(--secondary);
  margin: 1rem;
  text-align: center;
`

const HeaderPost = styled(Link)`
  box-shadow: 3px 3px 5px gray;
  border: 1px solid gray;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: auto;
  text-decoration: none;
  align-items: center;
  max-width: 250px;
  margin-bottom: 3rem;
  height: 320px;
`

const Title = styled.h2`
  margin-bottom: 1rem;
`

const Description = styled.p`
  //padding-bottom: 0.9rem;
  padding: 0 0.5rem 1rem 0.5rem 0;
`

const Post = ({ metadata }) => {
  const { title, date, tag, path, img, description, alt } = metadata

  const imgPath = img || "css_blog.png"
  const image = UseImage(imgPath, alt)

  return (
    <>
      <HeaderPost to={path}>
        {image}
        <CardBody>
          <Title>{title}</Title>
          <Description>{description}</Description>
        </CardBody>
      </HeaderPost>
    </>
  )
}

export default Post
